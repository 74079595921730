import React, { useState } from 'react';
import UserDetails from './UserDetails';


const ProductDetails = ({ product }) => {
  const [selectedImage, setSelectedImage] = useState(product.images[0]);
  const [quantity, setQuantity] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleQuantityChange = (type) => {
    setQuantity((prevQuantity) => {
      if (type === "increment") return prevQuantity + 1;
      if (type === "decrement" && prevQuantity > 1) return prevQuantity - 1;
      return prevQuantity;
    });
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const renderDescription = () => {
    const charLimit = window.innerWidth >= 1024 ? 500 : 300;

    if (product.description.length > charLimit && !isDescriptionExpanded) {
      return (
        <>
          {product.description.substring(0, charLimit)}...
          <button
            className="text-blue-500 underline ml-2"
            onClick={toggleDescription}
          >
            Read More
          </button>
        </>
      );
    }
    return (
      <>
        {product.description}
        {product.description.length > charLimit && (
          <button
            className="text-blue-500 underline ml-2"
            onClick={toggleDescription}
          >
            Read Less
          </button>
        )}
      </>
    );
  };

  const handleProceedToDetails = () => {
    setIsDialogOpen(false);
    setIsDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    setIsDetailsOpen(false);
  };

  const handleDetailsSubmit = (formData) => {
    // console.log("User Details:", formData);
    setIsDetailsOpen(false);
    // Proceed with the order submission logic
  };

  return (
    <div className="p-6 gap-6 flex flex-col md:flex-row bg-white shadow-[0px_-1px_21px_-7px_rgba(0,_0,_0,_0.35)] rounded-lg">
      {/* Image Section */}
      <div className="w-full md:w-1/2 flex flex-row justify-center items-center gap-10">
        <div className="flex flex-col gap-4 justify-center">
          {product.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={product.title}
              className={`w-16 h-16 md:w-20 md:h-20 object-cover cursor-pointer border-2 rounded-lg ${
                selectedImage === image ? "border-blue-500" : "border-gray-200"
              }`}
              onClick={() => handleImageClick(image)}
            />
          ))}
        </div>

        <div className="flex justify-center items-center">
          <img
            src={selectedImage}
            alt={product.title}
            className="w-64 h-64 md:w-80 md:h-80 object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Product Details */}
      <div className="w-full md:w-1/2 flex flex-col items-center space-y-4 mt-6  md:mt-0 md:ml-6 md:items-start">
        <h1 className="text-xl lg:text-2xl font-bold text-gray-800 text-center md:text-left">{product.title}</h1>
        <p className="text-gray-600 whitespace-pre-line md:text-lg text-center md:text-left">{renderDescription()}</p>

        <div className="text-lg font-semibold text-center lg:text-left">
          <span className="text-gray-500 line-through mr-2">₹{product.originalPrice}</span>
          <span className="text-green-600">₹{product.discountedPrice}</span>
          {product.discount && (
            <span className="ml-2 px-2 py-1 bg-red-100 text-red-600 text-xs font-semibold rounded-full">{product.discount}% OFF</span>
          )}
        </div>

        <div className="flex flex-row items-center space-x-4">
        <div className="flex items-center space-x-4">
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg"
            onClick={() => handleQuantityChange("decrement")}
          >
            -
          </button>
          <span className="text-lg font-semibold">{quantity}</span>
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg"
            onClick={() => handleQuantityChange("increment")}
          >
            +
          </button>
        </div>

        <button
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700"
          onClick={toggleDialog}
        >
          Buy Now
        </button>
        </div>
      </div>

      {/* Dialog Box */}
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Order Summary</h2>
            <p>Product: {product.title}</p>
            <p>Quantity: {quantity}</p>
            <p>Price: ₹{product.discountedPrice} x {quantity}</p>
            <p className="font-semibold">Total: ₹{product.discountedPrice * quantity}</p>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-200 rounded-lg mr-2 hover:bg-red-600 hover:text-white transition-colors duration-200"
                onClick={toggleDialog}
              >
                Close
              </button>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={handleProceedToDetails}>
                Proceed to buy 
              </button>
            </div>
          </div>
        </div>
      )}
      {isDetailsOpen && (
        <UserDetails onClose={handleDetailsClose} onProceed={handleDetailsSubmit} />
      )}
    </div>
  );
};


export default ProductDetails;
