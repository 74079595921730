import React, { useState, useEffect, useRef } from 'react';
import { useScroll, useMotionValueEvent } from 'framer-motion';
import Introduction from '../components/Team/Introduction';
import Questions from '../components/Team/Question';
import Solutions from '../components/Team/Solution';
import Services from '../components/Team/Services';
import Team from '../components/Team/Team';
import Contact from '../components/Team/Contact';
import NavigationPath from '../components/Team/NavigationPath';
import AnimatedSection from '../components/Team/AnimatedSection';
import Connection from '../components/Team/Connection';

export default function OurTeam() {
  const [currentSection, setCurrentSection] = useState(0);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });

  const sections = [
    Introduction,
    Questions,
    Solutions,
    Services,
    Team,
    Contact,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useMotionValueEvent(scrollYProgress, 'change', (v) => {
    const newSection = Math.floor(v * sections.length);
    if (newSection !== currentSection && newSection < sections.length) {
      setCurrentSection(newSection);
    }
  });

  return (
    <div ref={containerRef} className="relative bg-gradient-to-r from-cyan-100 to-indigo-200 min-h-screen" >
      <NavigationPath
        currentSection={currentSection}
        totalSections={sections.length}
        className="px-4 sm:px-6 lg:px-8"
      />
      {sections.map((Section, index) => (
        <React.Fragment key={index}>
          <AnimatedSection
            progress={scrollYProgress}
            index={index}
            totalSections={sections.length}
            initiallyVisible={index === 0}
            className="px-4 sm:px-6 lg:px-8 mt-100px"
          >
            <Section />
          </AnimatedSection>
          {index < sections.length - 1 && (
            <Connection className="my-4 sm:my-6 lg:my-8" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}