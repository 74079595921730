import React from 'react';
import { motion } from 'framer-motion';

export default function Connection() {
  return (
    <div className="h-16 sm:h-24 flex items-center justify-center">
      <motion.div
        className="w-1 h-full bg-blue-500"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      />
    </div>
  );
}
