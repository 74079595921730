import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MediaCoverage from '../components/media/MediaCoverage';
import VideoPresence from '../components/media/VideoPresence';
import Gallery from '../components/media/Gallery';
import Blog from './Blog';

const MediaPage = () => {
  const [activeSection, setActiveSection] = useState('mediaCoverage');

  const renderSection = () => {
    switch(activeSection) {
      case 'mediaCoverage':
        return <MediaCoverage />;
      case 'videoPresence':
        return <VideoPresence />;
      case 'gallery':
        return <Gallery />;
      case 'blogs':
        return <Blog />;
      default:
        return <MediaCoverage />;
    }
  };

  // Scroll to top on section change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeSection]);

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-4xl font-heading font-bold text-text-100 my-8">Media</h1>
      <nav className="mb-8">
        <ul className="flex space-x-4">
          {[
            { name: 'IN THE NEWS', key: 'mediaCoverage' },
            { name: 'VIDEO & AUDIO', key: 'videoPresence' },
            { name: 'GALLERY', key: 'gallery' },
            { name: 'BLOGS', key: 'blogs' }
          ].map((section) => (
            <motion.li
              key={section.key}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <button
                className={`px-4 py-2 rounded-md ${activeSection === section.key ? 'bg-primary-100 text-white' : 'bg-bg-200 text-text-100'}`}
                onClick={() => setActiveSection(section.key)}
              >
                {section.name}
              </button>
            </motion.li>
          ))}
        </ul>
      </nav>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="aspect-w-16 aspect-h-9">
            {renderSection()}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default MediaPage;
