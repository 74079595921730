import { motion } from 'framer-motion';

export default function NavigationPath({ currentSection, totalSections }) {
  return (
    <div className="fixed left-2 sm:left-4 md:left-6 top-1/2 transform -translate-y-1/2 w-1 h-1/2 z-50">
      <div className="relative h-full">
        {[...Array(totalSections)].map((_, index) => (
          <motion.div
            key={index}
            className="absolute w-2 h-2 sm:w-3 sm:h-3 bg-blue-500 rounded-full"
            style={{ top: `${(index / (totalSections - 1)) * 100}%` }}
            initial={{ scale: 0.5, opacity: 0.5 }}
            animate={{
              scale: currentSection === index ? 1.5 : 1,
              opacity: currentSection === index ? 1 : 0.5,
            }}
            transition={{ duration: 0.5 }}
          />
        ))}
        <motion.div
          className="absolute left-0.5 w-2 bg-blue-500"
          style={{
            top: 0,
            height: `${(currentSection / (totalSections - 1)) * 100}%`,
          }}
          transition={{ duration: 0.5 }}
        />
      </div>
    </div>
  );
}
