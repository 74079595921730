import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaInstagram, FaFacebook, FaLinkedin, FaYoutube} from 'react-icons/fa';
import { services } from '../data/services';
import Locations from './Locations'

function Footer() {
  const sortedServices = [...services].sort((a, b) => a.id.length - b.id.length);
  const firstColumnServices = sortedServices.filter((_, index) => index % 2 === 0);
  const secondColumnServices = sortedServices.filter((_, index) => index % 2 !== 0);

  return (
    <footer className="bg-white text-gray-800 p-8 mt-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        <FooterSection title="Join Our Community">
          <p className="mb-4">Modern healthcare for mental wellness — Join our Support Groups for compassionate, inclusive care from licensed professionals.</p>
          <Link to="https://chat.whatsapp.com/F4WM6hnx36ZB7xkZmQKVgO" target="_blank" rel="noopener noreferrer" className="bg-primary-100 text-white px-4 py-2 rounded hover:bg-primary-200 transition-colors duration-300">Join Our Community</Link>
        </FooterSection>


        <FooterSection title="Company">
          <FooterLink to="/Squad">About Us</FooterLink>
          <FooterLink to="/services">Conditions we Aid</FooterLink>
          <FooterLink to="/diagnostics">Diagnostics</FooterLink>
          <FooterLink to="/for-business">For Business</FooterLink>
          <FooterLink to="/gift-a-friend">Gift a Friend</FooterLink>
        </FooterSection>

        <FooterSection title="Service Treatments">
          <FooterLink to="/services/counselling">Counselling</FooterLink>
          <FooterLink to="/services/neuromodulation">Neuromodulation</FooterLink>
          <FooterLink to="/services/medications">Medications</FooterLink>
          <FooterLink to="/services/lab">Lab</FooterLink>
        </FooterSection>

        <FooterSection title="Diagnostic Services">
          <div className="grid grid-cols-2 gap-2">
            <div>
              {firstColumnServices.map(service => (
                <FooterLink key={service.id} to={`/service/${service.id}`}>
                  {service.id.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </FooterLink>
              ))}
            </div>
            <div>
              {secondColumnServices.map(service => (
                <FooterLink key={service.id} to={`/service/${service.id}`}>
                  {service.id.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                </FooterLink>
              ))}
            </div>
          </div>
        </FooterSection>
      </div>
      <Divider />
      <Locations/>

      <Divider />
      <div className="mt-8 text-center py-4 bg-gray-800 text-white rounded-2xl">
        <p className='text-xl'>© 2024 Dopamine for Thought</p>
        <p className="text-sm mt-1">by Dr. Madhu Vamsi</p>

        <div className="mt-4 space-x-4">
          <SocialIcon href="https://www.instagram.com/MADHU_VAMSI" icon={FaInstagram} />
          <SocialIcon href="https://www.facebook.com/madhu.vamsi/" icon={FaFacebook} />
          <SocialIcon href="https://www.linkedin.com/company/dopamineforthought/" icon={FaLinkedin} />
          <SocialIcon href="https://www.youtube.com/@drmadhuvamsi" icon={FaYoutube} />
        </div>
      </div>
      <div className="mt-4 text-center text-sm text-gray-500">
        <a 
          href="https://www.linkedin.com/in/ganduri-sreeshanth-12b04a1b3/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-accent-100 hover:underline hover:text-primary-100 transition-colors duration-300"
        >
          Developed by G. Sreeshanth 🐾
        </a>
      </div>
      </footer>
  );
}

function Divider() {
  return <hr className="my-8 border-gray-200" />;
}

function FooterSection({ title, children }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <motion.h3 
        className="text-lg font-semibold mb-4"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        {title}
      </motion.h3>
      <ul className="space-y-2">
        {children}
      </ul>
    </motion.div>
  );
}

function FooterLink({ to, children }) {
  return (
    <motion.li
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link to={to} className="hover:text-primary-100 transition-colors duration-300">
        {children}
      </Link>
    </motion.li>
  );
}

function SocialIcon({ href, icon: Icon }) {
  return (
    <motion.a
      href={href}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      className="inline-block text-gray-400 hover:text-primary-100 transition-colors duration-300"
    >
      <Icon className="text-2xl" />
    </motion.a>
  );
}

// function ClinicLocation({ name, address, mapsLink }) {
//   return (
//     <motion.div
//       className="text-center"
//       whileHover={{ scale: 1.05 }}
//       transition={{ type: "spring", stiffness: 300 }}
//     >
//       <h4 className="font-semibold">{name}</h4>
//       <p>{address}</p>
//       <motion.a 
//         href={mapsLink}
//         target="_blank"
//         rel="noopener noreferrer"
//         className="text-primary-100 hover:underline"
//         whileHover={{ scale: 1.1 }}
//         whileTap={{ scale: 0.95 }}
//       >
//         View on Google Maps
//       </motion.a>
//     </motion.div>
//   );
// }

export default Footer;