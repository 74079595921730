import React from 'react';
import { motion } from 'framer-motion';
// import shopping_cart from '../assets/shopping_cart'


const OurProductsHero = () => {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 1 }}
      className="flex flex-col items-center text-center py-20 bg-gradient-to-r from-primary-200 to-primary-300 rounded-b-3xl">
      
      {/* Image Section */}
      {/* <div className="w-full flex justify-between items-center">
        <img src="path-to-left-hand-image.png" alt="Left Hand" className="w-24 h-auto" />
        <img src="path-to-right-hand-image.png" alt="Right Hand" className="w-24 h-auto" />
      </div> */}
      
      <div className="mt-8">
        <h1 className="text-4xl font-heading text-text-100">Shop our <span className="text-accent-100 italic">Products</span></h1>
        {/* <p className="text-lg mt-4 text-text-200">The Journey to Self-Discovery Starts Here – 10% Off on One, 15% Off on Two or More!</p> */}
        <p className="text-lg mt-4 text-text-200">The Journey to Self-Discovery Starts Here - Enjoy 10% Off on Every Item!</p>
      </div>
      
      <div className="mt-12">
        <motion.button
          className="text-6xl text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-90 transition-colors cursor-default"
          whileHover={{ scale: 1.2, rotate: 10 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
          {/* <img src={shopping_cart} alt="shopping cart" className="w-10 h-10" /> */}
        </motion.button>
      </div>
    </motion.div>
  );
};

export default OurProductsHero;
