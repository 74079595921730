import React, { useState, useEffect, useCallback } from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { motion } from 'framer-motion';

const Locations = () => {
  const [showLocations, setShowLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // This function handles clicking the location and showing clinic details
  const handleClickLocation = (location) => {
    setSelectedLocation(location);  // Set the selected location
    setShowLocations(true);         // Show the details after clicking the location
  };

  // This function handles closing the clinic details div
  const handleCloseDetails = () => {
    setShowLocations(false);        // Hide the details div
    setSelectedLocation(null);      // Reset the selected location
  };

  // This function checks if the click happened outside the location div
  const handleClickOutside = useCallback((e) => {
    if (!e.target.closest(".locations-container") && showLocations) {
      handleCloseDetails();
    }
  }, [showLocations]);

  // Attach click event listener to close when clicking outside the location div
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]); // Include handleClickOutside as a dependency


  return (
    <div className="locations-container">
      {/* Show the buttons to select a location if no location is selected */}
      {/* {!showLocations && ( */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col items-center justify-center p-2 md:p-6 mx-4 bg-cover bg-center h-40 md:h-full rounded-lg" 
        style={{ backgroundImage: 'url(https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsX29mZmljZV8yNV9zaW1wbGVfM2RfaWxsdXN0cmF0aW9uX29mX2FfcmVjb3Zlcnlfcm9vbV93aV80ZjhkNDIwNC02N2I4LTQwMDQtYTBlNy05YjljMjIyMzE2ZGVfMS5qcGc.jpg)' }}>
          <h2 className="text-2xl font-semibold mb-4">Hyderabad Clinics</h2>
          <button
            onClick={() => handleClickLocation("Hyderabad")}
            className="text-red-600 text-base font-semibold rounded rounded hover:text-blue-500 hover:underline"
          >
            Click here to view Hyderabad clinics 
          </button>
        </div>
        <div className="flex flex-col items-center justify-center p-2 md:p-6 mx-5 bg-cover bg-center h-40 md:h-full rounded-lg" 
        style={{ backgroundImage: 'url(https://static.vecteezy.com/system/resources/previews/029/278/288/non_2x/ideal-healthcare-background-with-surrealist-blurry-hospital-scene-ai-generative-free-photo.jpg)' }}>
          <h2 className="text-2xl font-semibold mb-4">Visakhapatnam Clinics</h2>
          <button
            onClick={() => handleClickLocation("Visakapatnam")}
            className="text-red-600 text-base font-semibold rounded rounded hover:text-blue-500 hover:underline"
          >
            Click here to view Visakhapatnam clinics
          </button>
        </div>
      </div>
      {/* )} */}

      {/* Show details for Hyderabad if selected */}
      {showLocations && selectedLocation === "Hyderabad" && (
        <div className="mt-8 p-6 border-t-2 border-gray-200">
          <FooterSection title="Contact">
            <div className="flex flex-col justify-center items-center">
              <div className="space-y-2">
                <p className="flex items-center justify-center">
                  <FaPhone className="mr-2 text-lg text-green-500" />
                  <a href="tel:+916303441604" className="text-lg text-blue-500">
                    +91 63034 41604 
                  </a>
                </p>
                <p className="flex items-center justify-center">
                  <FaEnvelope className="mr-2 text-lg text-blue-500" />
                  madhu.vamsi19@gmail.com
                </p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <ClinicLocation
                    name="Baghlingampally Clinic"
                    address="HIG-II, B-12, F-1, near BR Ambedkar College, Bagh Lingampally, New Nallakunta, Hyderabad, Telangana 500044"
                    mapsLink="https://www.google.com/maps?ll=17.401739,78.499721&z=11&t=m&hl=en&gl=IN&gl=IN&mapclient=embed&cid=1389178772765863322"
                  />
                  <ClinicLocation
                    name="Saidabad Clinic"
                    address="LIC Colony Rd, opposite Balaji Mithaiwala, Madhava Nagar Colony, Bramhannada Nagar Colony, Saidabad, Hyderabad, Telangana 500059"
                    mapsLink="https://www.google.com/maps?ll=17.362702,78.510787&z=11&t=m&hl=en&gl=IN&mapclient=embed&cid=15662029146274489509"
                  />
                  <ClinicLocation
                    name="Alwal Clinic"
                    address="Plot No. 13, 1-23-564, Rajiv Nagar, Bhudevi Nagar, Venkatapuram, Alwal, Secunderabad, Telangana 500015"
                    mapsLink="https://www.google.com/maps?ll=17.48925,78.503408&z=11&t=m&hl=en&gl=IN&gl=IN&mapclient=embed&cid=14181434610183267613"
                  />
                  <ClinicLocation
                    name="Chaitanyapuri Clinic"
                    address="1st Floor, Chaitanyapuri Main Rd, opposite Sri Lakshmi Medical, Chaitanyapuri, Dilsukhnagar, Hyderabad, Telangana 500060"
                    mapsLink="https://maps.app.goo.gl/6M3Rxgo4Z7PfpifF7"
                  />
                </div>
              </div>
            </div>
          </FooterSection>
          <div className="flex items-center justify-center">
          <button
            onClick={handleCloseDetails}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
          >
            Close Details
          </button>
          </div>
        </div>
      )}

      {/* Show details for Visakapatnam if selected */}
      {showLocations && selectedLocation === "Visakapatnam" && (
        <div className="mt-8 p-6 border-t-2 border-gray-200 flex flex-col justify-center items-center">
          <FooterSection title="Contact">
            <div className="grid grid-cols-1 gap-8 items-center text-center">
              <div className="space-y-2">
                <p className="flex items-center justify-center">
                  <FaPhone className="mr-2 text-lg text-green-500" />
                  <a href="tel:+916303441604" className="text-lg text-blue-500">
                    +91 90636 28758
                  </a>
                </p>
                <p className="flex items-center justify-center">
                  <FaEnvelope className="mr-2 text-lg text-blue-500" />
                  madhu.vamsi19@gmail.com
                </p>
              </div>
              </div>
              <ClinicLocation
                name="MV Clinics"
                address="1-72-29 MIG 2-141, sector 3, MVP colony, Visakhapatnam, 530017"
                // mapsLink="https://maps.app.goo.gl/6M3Rxgo4Z7PfpifF7"
              />
          </FooterSection>
          <button
            onClick={handleCloseDetails}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-200"
          >
            Close Details
          </button>
        </div>
      )}
    </div>
  );
};

function FooterSection({ title, children }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="text-center"
    >
      <motion.h3
        className="text-lg font-semibold mb-4 text-gray-800"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        {title}
      </motion.h3>
      <ul className="space-y-4">
        {children}
      </ul>
    </motion.div>
  );
}

function ClinicLocation({ name, address, mapsLink }) {
  return (
    <motion.div
      className="text-center bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <h4 className="font-semibold text-base">{name}</h4>
      <p className="text-gray-600 text-sm">{address}</p>
      <motion.a
        href={mapsLink}
        target="_blank"
        rel="noopener noreferrer"
        className="text-primary-100 hover:underline mt-2 inline-block"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        View on Google Maps
      </motion.a>
    </motion.div>
  );
}

export default Locations;