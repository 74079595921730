import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import OurProductsHero from '../components/ourProducts/ProductsHero';
import ProductList from '../components/ourProducts/ProductList';



const OurProducts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="mb-36"
      >
        <OurProductsHero />
      </motion.div>

      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <ProductList />
      </motion.div>

    </div>
  );
};

export default OurProducts;